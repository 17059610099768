import { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { InputAdornment, TextField } from '@mui/material';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import Scrollbar from 'src/components/scrollbar';
import { isEqual } from 'lodash';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
import UserTableFiltersResult from 'src/sections/user/user-table-filters-result';
import UserRoleSiteTableRow from '../user-role-site-table-row';

const TABLE_HEAD = [
  { id: 'site', label: 'Site' },
  { id: 'role', label: 'Role' },
];

const defaultFilters = {
  site: '',
  role: '',
};

export default function UserRoleSiteTableView() {
  const token = sessionStorage.getItem('token');
  const user = JSON.parse(sessionStorage.getItem('user'));

  const table = useTable();

  const settings = useSettingsContext();
  const router = useRouter();
  const confirm = useBoolean();
  const openFilters = useBoolean();

  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchSites = useCallback(async () => {
    try {
      const response = await axios.post(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/userrole/getbyuser`,
        user,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const modifiedData = response.data.map((item) => ({
        site: item?.subscrSite?.subscrSiteName || '-',
        role: item?.roleId?.roleName || '-',
        siteId: item?.subscrSite?.subscrSiteId || '-',
      }));

      setTableData(modifiedData); // Set data directly
    } catch (error) {
      console.error('Error fetching sites:', error);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSites();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilters = useCallback(
    (name, value) => {
      if (name === 'search') {
        setSearchQuery(value);
      } else {
        table.onResetPage();
        setFilters((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    },
    [table]
  );

  const dataFiltered = useMemo(
    () =>
      applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filters: {
          ...filters,
          site: searchQuery,
          role: searchQuery,
        },
      }),
    [tableData, table.order, table.orderBy, filters, searchQuery]
  );

  const dataInPage = useMemo(
    () =>
      dataFiltered.slice(
        table.page * table.rowsPerPage,
        table.page * table.rowsPerPage + table.rowsPerPage
      ),
    [dataFiltered, table.page, table.rowsPerPage]
  );

  const denseHeight = table.dense ? 52 : 68.67;
  const canReset = !isEqual(defaultFilters, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      <TextField
        value={searchQuery}
        onChange={(event) => handleFilters('search', event.target.value)}
        placeholder="Search ..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
        sx={{ marginBottom: 2, width: '100%', height: '50px' }}
      />
      <Card style={{ marginTop: '20px' }}>
        {canReset && (
          <UserTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={handleResetFilters}
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.siteId)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 360 }}>
              <TableHeadCustom
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={table.selected.length}
                onSort={table.onSort}
              />
              <TableBody>
                {dataInPage.map((row) => (
                  <UserRoleSiteTableRow
                    key={row.siteId}
                    row={row}
                    // selected={table.selected.includes(row.siteId)}
                    // onSelectRow={() => table.onSelectRow(row.siteId)}
                  />
                ))}
                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                />
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </>
  );
}

const applyFilter = ({ inputData, comparator, filters }) => {
  const { role, site } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  const filteredData = inputData.filter((item) => {
    const roleMatch = item.role.toLowerCase().includes(role.toLowerCase());
    const siteMatch = item.site.toLowerCase().includes(site.toLowerCase());
    return roleMatch || siteMatch;
  });

  return filteredData;
};



// import isEqual from 'lodash/isEqual';
// import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
// import axios from 'axios';

// // @mui
// import Card from '@mui/material/Card';
// import Table from '@mui/material/Table';
// import Tooltip from '@mui/material/Tooltip';
// import TableBody from '@mui/material/TableBody';
// import IconButton from '@mui/material/IconButton';
// import TableContainer from '@mui/material/TableContainer';
// import { InputAdornment, TextField } from '@mui/material';

// // routes
// import { useRouter } from 'src/routes/hooks';

// // hooks
// import { useBoolean } from 'src/hooks/use-boolean';

// // components
// import Iconify from 'src/components/iconify';
// import { useSettingsContext } from 'src/components/settings';
// import {
//   useTable,
//   getComparator,
//   emptyRows,
//   TableNoData,
//   TableEmptyRows,
//   TableHeadCustom,
//   TableSelectedAction,
//   TablePaginationCustom,
// } from 'src/components/table';
// import Scrollbar from 'src/components/scrollbar';
// import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
// import UserTableFiltersResult from 'src/sections/user/user-table-filters-result';
// import UserRoleSiteTableRow from '../user-role-site-table-row';

// const TABLE_HEAD = [
//   { id: 'site', label: 'Site' },
//   { id: 'role', label: 'Role' },
// ];

// const defaultFilters = {
//   site: '',
//   role: '',
// };

// export default function UserRoleSiteTableView() {
//   const token = sessionStorage.getItem('token');
//   const user = JSON.parse(sessionStorage.getItem('user'));

//   const table = useTable();

//   const settings = useSettingsContext();
//   const router = useRouter();
//   const confirm = useBoolean();
//   const openFilters = useBoolean();

//   const [tableData, setTableData] = useState([]);
//   const [filters, setFilters] = useState(defaultFilters);
//   const [searchQuery, setSearchQuery] = useState('');

//   const initialFetch = useRef(true);

//   const fetchSites = useCallback(async () => {
//     try {
//       const response = await axios.post(
//         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/userrole/getbyuser`,
//         user,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       const modifiedData = response.data.map((item) => ({
//         site: item?.subscrSite?.subscrSiteName || '-',
//         role: item?.roleId?.roleName || '-',
//         siteId: item?.subscrSite?.subscrSiteId || '-',
//       }));

//       setTableData((prevData) => {
//         if (!isEqual(prevData, modifiedData)) {
//           return modifiedData;
//         }
//         return prevData;
//       });
//     } catch (error) {
//       console.error('Error fetching sites:', error);
//       // Optionally, handle the error
//     }
//   }, [token, user]);

//   useEffect(() => {
//     if (initialFetch.current) {
//       fetchSites();
//       initialFetch.current = false;
//     }
//   }, [fetchSites]);

//   const handleFilters = useCallback(
//     (name, value) => {
//       if (name === 'search') {
//         setSearchQuery(value);
//       } else {
//         table.onResetPage();
//         setFilters((prevState) => ({
//           ...prevState,
//           [name]: value,
//         }));
//       }
//     },
//     [table]
//   );

//   const dataFiltered = useMemo(() => applyFilter({
//     inputData: tableData,
//     comparator: getComparator(table.order, table.orderBy),
//     filters: {
//       ...filters,
//       site: searchQuery,
//       role: searchQuery,
//     },
//   }), [tableData, table.order, table.orderBy, filters, searchQuery]);

//   const dataInPage = useMemo(() => dataFiltered.slice(
//     table.page * table.rowsPerPage,
//     table.page * table.rowsPerPage + table.rowsPerPage
//   ), [dataFiltered, table.page, table.rowsPerPage]);

//   const denseHeight = table.dense ? 52 : 68.67;
//   const canReset = !isEqual(defaultFilters, filters);
//   const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

//   const handleResetFilters = useCallback(() => {
//     setFilters(defaultFilters);
//   }, []);

//   return (
//     <>
//       <TextField
//         value={searchQuery}
//         onChange={(event) => handleFilters('search', event.target.value)}
//         placeholder="Search ..."
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
//             </InputAdornment>
//           ),
//         }}
//         sx={{ marginBottom: 2, width: '100%', height: '50px' }}
//       />
//       <Card style={{ marginTop: '20px' }}>
//         {canReset && (
//           <UserTableFiltersResult
//             filters={filters}
//             onFilters={handleFilters}
//             onResetFilters={handleResetFilters}
//             results={dataFiltered.length}
//             sx={{ p: 2.5, pt: 0 }}
//           />
//         )}
//         <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
//           <TableSelectedAction
//             dense={table.dense}
//             numSelected={table.selected.length}
//             rowCount={tableData.length}
//             onSelectAllRows={(checked) =>
//               table.onSelectAllRows(
//                 checked,
//                 tableData.map((row) => row.siteId)
//               )
//             }
//             action={
//               <Tooltip title="Delete">
//                 <IconButton color="primary" onClick={confirm.onTrue}>
//                   <Iconify icon="solar:trash-bin-trash-bold" />
//                 </IconButton>
//               </Tooltip>
//             }
//           />
//           <Scrollbar>
//             <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 360 }}>
//               <TableHeadCustom
//                 order={table.order}
//                 orderBy={table.orderBy}
//                 headLabel={TABLE_HEAD}
//                 rowCount={tableData.length}
//                 numSelected={table.selected.length}
//                 onSort={table.onSort}
//               />
//               <TableBody>
//                 {dataInPage.map((row) => (
//                   <UserRoleSiteTableRow
//                     key={row.siteId}
//                     row={row}
//                     // selected={table.selected.includes(row.siteId)}
//                     // onSelectRow={() => table.onSelectRow(row.siteId)}
//                   />
//                 ))}
//                 <TableEmptyRows
//                   height={denseHeight}
//                   emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
//                 />
//                 <TableNoData notFound={notFound} />
//               </TableBody>
//             </Table>
//           </Scrollbar>
//         </TableContainer>
//         <TablePaginationCustom
//           count={dataFiltered.length}
//           page={table.page}
//           rowsPerPage={table.rowsPerPage}
//           onPageChange={table.onChangePage}
//           onRowsPerPageChange={table.onChangeRowsPerPage}
//           dense={table.dense}
//           onChangeDense={table.onChangeDense}
//         />
//       </Card>
//     </>
//   );
// }

// const applyFilter = ({ inputData, comparator, filters }) => {
//   const { role, site } = filters;

//   const stabilizedThis = inputData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   inputData = stabilizedThis.map((el) => el[0]);

//   const filteredData = inputData.filter((item) => {
//     const roleMatch = item.role.toLowerCase().includes(role.toLowerCase());
//     const siteMatch = item.site.toLowerCase().includes(site.toLowerCase());
//     return roleMatch || siteMatch;
//   });

//   return filteredData.sort(comparator);
// };
