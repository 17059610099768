import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
//
import Iconify from '../iconify';
import RejectionFiles from './errors-rejection-files';

// ----------------------------------------------------------------------

export default function UploadBox({ placeholder, helperText, maxSize, minSize, error, disabled, accept,sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    disabled,
    maxSize,
    minSize,
    accept,
    ...other,
  });

  const hasError = isDragReject || error;
 

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          m: 0.5,
          width: 64,
          height: 64,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1,
          cursor: 'pointer',
          alignItems: 'center',
          color: 'text.disabled',
          justifyContent: 'center',
          // bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
          // border: (theme) => `dashed 1px ${alpha(theme.palette.grey[500], 0.16)}`,
          border: 'solid 1px rgba(145, 158, 171, 0.2)',
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          '&:hover': {
            opacity: 0.72,
            border: 'solid 1px rgb(0, 0, 0)',
          },
          ...sx,
        }}
      >
        <input {...getInputProps()} />

        {placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />}
      </Box>
      {helperText && helperText}
      <RejectionFiles fileRejections={fileRejections} maxSize={maxSize} minSize={minSize} acceptFile={accept} />
    </Box>
  );
}

UploadBox.propTypes = {
  disabled: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.object,
  placeholder: PropTypes.object,
  sx: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  accept: PropTypes.object,
};