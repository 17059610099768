import PropTypes from 'prop-types';
import { StyledEditorToolbar } from './styles';

// ----------------------------------------------------------------------

const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

export const formats = [
  'align',
  'background',
  'blockquote',
  'bold',
  'bullet',
  'code',
  'code-block',
  'color',
  'direction',
  'font',
  'formula',
  'header',
  'image',
  'indent',
  'italic',
  'link',
  'list',
  'script',
  'size',
  'strike',
  'table',
  'underline',
  'video',
];

export default function Toolbar({ id, isSimple, ...other }) {
  return (
    <StyledEditorToolbar {...other}>
      <div id={id}>
        <div className="ql-formats">
          <select aria-label="Heading" className="ql-header" defaultValue="">
            {HEADINGS.map((heading, index) => (
              <option key={heading} value={index + 1}>
                {heading}
              </option>
            ))}
            <option value="">Normal</option>
          </select>
        </div>

        <div className="ql-formats">
          <button aria-label="Bold" type="button" className="ql-bold" />
          <button aria-label="Italic" type="button" className="ql-italic" />
          <button aria-label="Underline" type="button" className="ql-underline" />
          <button aria-label="Strike" type="button" className="ql-strike" />
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <select aria-label="Text Color" className="ql-color" />
            <select aria-label="Background Color" className="ql-background" />
          </div>
        )}

        <div className="ql-formats">
          <button aria-label="Ordered List" type="button" className="ql-list" value="ordered" />
          <button aria-label="Bullet List" type="button" className="ql-list" value="bullet" />
          {!isSimple && (
            <button aria-label="Decrease Indent" type="button" className="ql-indent" value="-1" />
          )}
          {!isSimple && (
            <button aria-label="Increase Indent" type="button" className="ql-indent" value="+1" />
          )}
        </div>

        {!isSimple && (
          <div className="ql-formats">
            <button aria-label="Superscript" type="button" className="ql-script" value="super" />
            <button aria-label="Subscript" type="button" className="ql-script" value="sub" />
          </div>
        )}

        {!isSimple && (
          <div className="ql-formats">
            <button aria-label="Code Block" type="button" className="ql-code-block" />
            <button aria-label="Blockquote" type="button" className="ql-blockquote" />
          </div>
        )}

        <div className="ql-formats">
          <button
            aria-label="Right-to-Left Direction"
            type="button"
            className="ql-direction"
            value="rtl"
          />
          <select aria-label="Text Alignment" className="ql-align" />
        </div>

        <div className="ql-formats">
          <button aria-label="Insert Link" type="button" className="ql-link" />
          <button aria-label="Insert Image" type="button" className="ql-image" />
          <button aria-label="Insert Video" type="button" className="ql-video" />
        </div>

        <div className="ql-formats">
          {!isSimple && <button aria-label="Insert Formula" type="button" className="ql-formula" />}
          <button aria-label="Clean Formatting" type="button" className="ql-clean" />
        </div>
      </div>
    </StyledEditorToolbar>
  );
}

Toolbar.propTypes = {
  id: PropTypes.string,
  isSimple: PropTypes.bool,
};
