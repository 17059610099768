import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'src/auth/hooks';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgGradient } from 'src/theme/css';

export default function AuthClassicLayout({ children, image, title }) {
  const { method } = useAuthContext();
  const theme = useTheme();
  const upMd = useResponsive('up', 'md');
  const upLg = useResponsive('up', 'lg');

  const renderContent = (
    <Stack sx={{ width: '100%', maxWidth: 480, px: { xs: 2, md: 4 }, py: { xs: 4, md: 8 } }}>
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={4}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
        display: { xs: 'none', md: 'flex' }, // Hide on mobile, show on tablet and above
      }}
    >
      <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
        Hi, Welcome 👋
      </Typography>
      <Box
        component="img"
        alt="auth"
        src={image || '/assets/illustrations/illustration_dashboard.png'}
        sx={{ maxWidth: { xs: 300, md: 600 } }}
      />
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction={{ xs: 'column', lg: 'row' }}
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh' }}
    >
      {upLg && renderSection}
      {renderContent}
    </Stack>
  );
}

AuthClassicLayout.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  title: PropTypes.string,
};
