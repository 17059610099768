// Function to handle empty rows in a table
export function emptyRows(page, rowsPerPage, arrayLength) {
  return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

// Helper function to get the value from an object or nested object
function getValueByPath(obj, path) {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

// Comparator function to sort data in descending order
function descendingComparator(a, b, orderBy) {
  const aValue = getValueByPath(a, orderBy);
  const bValue = getValueByPath(b, orderBy);

  // Check if either value is null or undefined
  if (aValue === null || aValue === undefined) {
    return 1;
  }
  if (bValue === null || bValue === undefined) {
    return -1;
  }

  // Handle different data types (number, string, date, object)
  if (typeof bValue === 'number' && typeof aValue === 'number') {
    return bValue - aValue;
  }
  if (typeof bValue === 'string' && typeof aValue === 'string') {
    return bValue.localeCompare(aValue);
  }
  if (bValue instanceof Date && aValue instanceof Date) {
    return bValue - aValue;
  }
  if (typeof bValue === 'object' && typeof aValue === 'object') {
    // Create a string representation of the objects by joining their values
    const bString = Object.values(bValue).join('-');
    const aString = Object.values(aValue).join('-');
    
    // Compare the generated strings
    return bString.localeCompare(aString);
  }

  // Default comparison
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

// Function to get the comparator function based on the sort order
export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


// // ----------------------------------------------------------------------

// export function emptyRows(page, rowsPerPage, arrayLength) {
//   return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
// }

// function descendingComparator(a, b, orderBy) {
//   if (a[orderBy] === null) {
//     return 1;
//   }
//   if (b[orderBy] === null) {
//     return -1;
//   }
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// export function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }
