import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';

// ----------------------------------------------------------------------

export default function LoginButton({ sx }) {
  const PATH_AFTER_LOGIN_2 = paths.auth.jwt.login;

  const handleClick = () => {
    // Clear session storage
    sessionStorage.clear();
    // Optionally, you can also clear local storage
    localStorage.clear();
  };

  return (
    <Button
      // component={RouterLink}
      href={PATH_AFTER_LOGIN_2}
      variant="outlined"
      sx={{ mr: 1, ...sx }}
      onClick={handleClick}
    >
      Login
    </Button>
  );
}

LoginButton.propTypes = {
  sx: PropTypes.object,
};


// import PropTypes from 'prop-types';
// // @mui
// import Button from '@mui/material/Button';
// // routes
// import { RouterLink } from 'src/routes/components';
// import { paths } from 'src/routes/paths';
// // config
// import { PATH_AFTER_LOGIN } from 'src/config-global';

// // ----------------------------------------------------------------------

// export default function LoginButton({ sx }) {
//   const PATH_AFTER_LOGIN_2 = paths.auth.jwt.login;
//   return (
//     <Button
//       // component={RouterLink}
//       href={PATH_AFTER_LOGIN_2}
//       variant="outlined"
//       sx={{ mr: 1, ...sx }}
//     >
//       Login
//     </Button>
//   );
// }

// LoginButton.propTypes = {
//   sx: PropTypes.object,
// };
