import { memo, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';

import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
// components
import { NavSectionHorizontal } from 'src/components/nav-section';
import axios from 'axios';
//
import { HEADER } from '../config-layout';
import { useNavData } from './config-navigation';
import { HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

function NavHorizontal() {
  const theme = useTheme();

  const { user } = useMockedUser();

  const navData = useNavData();

  const [roleArr, setRoleArr] = useState([]);

  const token = sessionStorage.getItem('token');

  // const currentSubSite = JSON.parse(sessionStorage.getItem('selectedSubscrSite'));
  const currentUser = JSON.parse(sessionStorage.getItem('user'));

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        await axios
          .get(
            `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/roleConfig/rolesOfuser/${currentUser.userId}/1`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            
            setRoleArr(res.data.map((ur) => ur.roleId));
          });
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  return (
    <AppBar
      component="nav"
      sx={{
        top: HEADER.H_DESKTOP_OFFSET,
      }}
    >
      <Toolbar
        sx={{
          ...bgBlur({
            color: theme.palette.background.default,
          }),
        }}
      >
        <NavSectionHorizontal
          data={navData}
          config={{
            currentRole: roleArr,
          }}
        />
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}

export default memo(NavHorizontal);
